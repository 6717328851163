import {
  SEND_EMAIL_VERIFICATION_LINK,
  VERIFY_EMAIL,
} from "@/graphql/mutations/emailVerification";
import { apolloClient } from "@/plugins/apollo";
import { useUserStore } from "@/stores/user";

const emailVerifApi = {
  async sendVerificationLink() {
    const userStore = useUserStore();
    const user_id = userStore._id;

    // TODO: get user id on backend from graphql context
    if (!user_id) throw new Error("User is not authorized!");

    const { data } = await apolloClient.mutate({
      mutation: SEND_EMAIL_VERIFICATION_LINK,
      variables: {
        payload: {
          user_id,
        },
      },
    });

    return data.sendEmailVerificationLink;
  },

  async verify(verificationKey) {
    if (!verificationKey) throw new Error("No verification key provided!");

    const { data } = await apolloClient.mutate({
      mutation: VERIFY_EMAIL,
      variables: {
        payload: {
          verificationKey,
        },
      },
    });

    return data.verifyEmail;
  },
};

export default emailVerifApi;
