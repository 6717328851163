<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <component :is="ContentComponent" />
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" :isLoginFirst="true" />
  </div>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, ref } from "vue";
import useEmailVerification from "@/composables/useEmailVerification";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import useIsMobile from "@/composables/useIsMobile";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsTick from "@/components/Svg/Illustrations/SvgIllustrationsTick.vue";
import NavMobile from "@/components/NavMobile.vue";

const VERIFICATION_STATUS = {
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  LINK_EXPIRED: "LINK_EXPIRED",
  WRONG_KEY: "WRONG_KEY",
  ALREADY_VERIFIED: "ALREADY_VERIFIED",
};

const SUSPENSE_MIN_TIMEOUT_MS = 2000;

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    SvgIllustrationsTick,
    NavMobile,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const verificationStatus = ref(VERIFICATION_STATUS.PROCESSING);
    const canShowResult = ref(false);

    const ContentComponent = computed(() => {
      let status = verificationStatus.value;
      if (canShowResult.value === false) {
        status = VERIFICATION_STATUS.PROCESSING;
      }
      switch (status) {
        case VERIFICATION_STATUS.PROCESSING:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLinkProcessing.vue"
            );
          });

        case VERIFICATION_STATUS.SUCCESS:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLinkSuccess.vue"
            );
          });

        case VERIFICATION_STATUS.ALREADY_VERIFIED:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLinkAlreadyVerified.vue"
            );
          });

        case VERIFICATION_STATUS.LINK_EXPIRED:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLinkLinkExpired.vue"
            );
          });

        case VERIFICATION_STATUS.WRONG_KEY:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLinkWrongKey.vue"
            );
          });

        default:
          return defineAsyncComponent(() => {
            return import(
              "@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLinkWrongKey.vue"
            );
          });
      }
    });

    const route = useRoute();
    const userStore = useUserStore();
    const { verifyEmail } = useEmailVerification();

    const tryToVerifyEmail = async () => {
      try {
        const { key } = route.query;
        await verifyEmail(key);
        userStore.emailIsVerified = true;
        setStatus(VERIFICATION_STATUS.SUCCESS);
      } catch (error) {
        handleError(error);
      }
    };

    const handleError = (error) => {
      const { graphQLErrors } = error;
      if (!graphQLErrors || graphQLErrors.length === 0) {
        return setStatus(VERIFICATION_STATUS.WRONG_KEY);
      }
      const { code } = graphQLErrors[0].extensions;
      if (code === VERIFICATION_STATUS.LINK_EXPIRED) {
        return setStatus(VERIFICATION_STATUS.LINK_EXPIRED);
      }
      if (code === VERIFICATION_STATUS.ALREADY_VERIFIED) {
        return setStatus(VERIFICATION_STATUS.ALREADY_VERIFIED);
      }
      if (code === VERIFICATION_STATUS.WRONG_KEY) {
        return setStatus(VERIFICATION_STATUS.WRONG_KEY);
      }
      return setStatus(VERIFICATION_STATUS.WRONG_KEY);
    };

    const setStatus = (status) => {
      verificationStatus.value = status;
    };

    const setSuspenseMinTimeout = () => {
      setTimeout(() => {
        canShowResult.value = true;
      }, SUSPENSE_MIN_TIMEOUT_MS);
    };

    onMounted(() => {
      tryToVerifyEmail();
      setSuspenseMinTimeout();
    });

    return {
      ContentComponent,
      isMobile,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 2 * var(--header-default--height));
}
@media (max-width: 1200px) {
  .container {
    min-height: 100%;
  }
}
</style>
