import gql from "graphql-tag";

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($payload: VerifyEmailInput!) {
    verifyEmail(payload: $payload)
  }
`;

export const SEND_EMAIL_VERIFICATION_LINK = gql`
  mutation SendEmailVerificationLink(
    $payload: SendEmailVerificationLinkInput!
  ) {
    sendEmailVerificationLink(payload: $payload)
  }
`;
