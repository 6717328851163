import emailVerifApi from "@/api/emailVerification";

const emailVerificationService = {
  async sendVerificationLink() {
    console.log(">>>SEND EMAIL VERIFICATION LINK<<<");
    return await emailVerifApi.sendVerificationLink();
  },

  async verify(verificationKey) {
    console.log(">>>VERIFY EMAIL<<<");
    return await emailVerifApi.verify(verificationKey);
  },
};

export default emailVerificationService;
