import emailVerificationService from "@/services/emailVerificationService";
import { useUserStore } from "@/stores/user";

export default function useEmailVerification() {
  const userStore = useUserStore();

  const sendEmailVerificationLink = async () => {
    userStore.emailIsVerificationLinkSent = true;
    await emailVerificationService.sendVerificationLink();
    return;
  };

  const verifyEmail = async (verificationKey) => {
    return await emailVerificationService.verify(verificationKey);
  };

  return {
    sendEmailVerificationLink,
    verifyEmail,
  };
}
